.container {
    position: fixed;
    bottom: 20px;
    right: 20px;
}

.visible {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.3s, visibility 0.3s;
}

.invisible {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0.3s;
}

.scroll_button {
    width: 15vw;
    height: 15vw;

    align-items: center;
    background-image: linear-gradient(144deg,#fc00ff, #00dbde);
    border: 0;
    border-radius: 50%;
    box-sizing: border-box;
    color: #FFFFFF;
    display: flex;
    font-size: 20px;
    justify-content: center;
    line-height: 1em;
    padding: 3px;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    cursor: pointer;
}

.scroll_button span {
    background-color: rgb(5, 6, 45);
    border-radius: 50%;
    width: 100%;
    height: 100%;
    transition: 300ms;
    display: flex;
    align-items: center;
    justify-content: center;
}

.scroll_button:hover span {
    background: none;
}

@media (min-width: 767px) {
    .scroll_button {
        width: 75px;
        height: 75px;
    }
}