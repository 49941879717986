.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 90%;

    h1, h2, h3, h4, h5, h6 {
        margin: 5px 0 0 0;
    }
}

.image {
    min-width: 20vw;
    width: 20vw;
    display: flex;
    justify-content: center;
    img {
        width: 100%;
        height: auto;
        border-radius: 50%;
        background: rgb(5, 6, 45);;
    }

    border-radius: 50%;
    padding: 3px;
    background-image: linear-gradient(144deg,#fc00ff, #00dbde);
}

.flavor {
    color: #adadad;
}

@media (min-width: 767px) {
    .image {
        min-width: 100px;
        width: 100px;
    }
}