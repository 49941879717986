body {
    background-color: #181a21;
    color: #FFFFFF;
    overflow-x: hidden;
}

@media (min-width: 767px) {
    .app {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}