.container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.section {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.note {
    width: 95%;
    text-align: center;
    color: #adadad;
    margin: 0;

    a {
        color: #adadad;
    }
}

.asterisk {
    color: #adadad;
    font-size: 0.8em;
}

.skills {
    width: 90%;
    text-align: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 25vw;
}

.skills_entries {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;

    p {
        margin: 0;
    }
}

.scroll_button {
    width: 10vw;
    height: 10vw;

    align-items: center;
    background-image: linear-gradient(144deg,#fc00ff, #00dbde);
    border: 0;
    border-radius: 50%;
    box-sizing: border-box;
    color: #FFFFFF;
    display: flex;
    font-size: 20px;
    justify-content: center;
    line-height: 1em;
    padding: 3px;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    cursor: pointer;
}

.scroll_button span {
    background-color: rgb(5, 6, 45);
    border-radius: 50%;
    width: 100%;
    height: 100%;
    transition: 300ms;
    display: flex;
    align-items: center;
    justify-content: center;
}

.scroll_button:hover span {
    background: none;
}

@media (min-width: 767px) {
    .container {
        flex: 1;
    }

    .scroll_button {
        width: 50px;
        height: 50px;
    }

    .skills {
        margin-bottom: 100px;
    }
}