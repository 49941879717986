.container {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
}

.portrait_border {
    border-radius: 50%;
    width: 55vw;
    height: 55vw;
    padding: 6px;
    background-image: linear-gradient(144deg,#fc00ff, #00dbde);
    margin: auto;

    img {
        border-radius: 50%;
        width: 55vw;
        height: 55vw;
    }
}

.button_styled {
    align-items: center;
    background-image: linear-gradient(144deg,#fc00ff, #00dbde);
    border: 0;
    border-radius: 8px;
    box-sizing: border-box;
    color: #FFFFFF;
    display: flex;
    font-size: 20px;
    justify-content: center;
    line-height: 1em;
    min-width: 140px;
    padding: 3px;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    cursor: pointer;
}

.button_styled span {
    background-color: rgb(5, 6, 45);
    padding: 16px 24px;
    border-radius: 6px;
    width: 100%;
    transition: 300ms;
    font-size: 0.8em;
}

.button_styled:hover span {
    background: none;
}

.between_text {
    font-size: 1.3rem;
}

.buttons {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 2rem auto;
    gap: 1rem;
}

.text_transition {
    overflow: hidden;
}

@media (min-width: 767px) {
    .container {
        flex: 1;
    }

    .portrait_border {
        width: 300px;
        height: 300px;
        img {
            width: 300px;
            height: 300px;
        }
    }
}

.subtext {
    font-style: italic;
    color: #adadad;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 15px;
}